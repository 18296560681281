export default function initializeCounter({
  counterEl,
  targetEl,
  targetElDataProp,
  onChange = () => {},
}) {
  // TODO add warnings to sentry if counter cannot initialize
  if (!counterEl || (targetEl && !targetElDataProp)) {
    return;
  }

  const addBtnEl = counterEl.querySelector(".add");
  const removeBtnEl = counterEl.querySelector(".remove");
  const getValue = () => {
    return parseInt(counterEl.querySelector(".value").innerText, 10);
  };
  const setValue = (value) => {
    return (counterEl.querySelector(".value").innerText = value);
  };

  // adding
  addBtnEl.addEventListener("click", function () {
    const prevValue = getValue();
    if (prevValue === 1) {
      removeBtnEl.classList.remove("is-disabled");
    }

    const newValue = prevValue + 1;
    if (newValue >= 100) {
      return;
    }

    if (targetEl) {
      targetEl.dataset[targetElDataProp] = newValue;
    }
    setValue(newValue);
    onChange(newValue);
  });

  // deleting
  removeBtnEl.addEventListener("click", function () {
    const prevValue = getValue();
    const newValue = prevValue - 1;
    if (prevValue <= 1) {
      removeBtnEl.classList.add("is-disabled");
      return;
    }
    if (targetEl) {
      targetEl.dataset[targetElDataProp] = newValue;
    }
    setValue(newValue);
    onChange(newValue);
  });

  const reset = (value = 1) => {
    setValue(value);
    onChange(value);
  };

  return {
    reset,
  };
}
