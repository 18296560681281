const uploadImageInput = document.querySelector(
    ".js-payment__image-upload-input",
  ),
  uploadImageStatus = document.querySelector(
    ".js-payment__image-upload-status",
  );

function imageUpload() {
  if (!uploadImageInput.files.length) {
    uploadImageStatus.innerHTML = "Brak załącznika";
  } else if (uploadImageInput.files.length) {
    uploadImageStatus.innerHTML = uploadImageInput.files[0].name;
  }
}

if (uploadImageInput) {
  uploadImageInput.addEventListener("change", imageUpload);
}
