function createImage(image, defaultImage) {
  const element = document.createElement("img");
  element.alt = image ? "Zdjęcie osoby wspierającej" : "Serduszko";
  element.src = image ? image : defaultImage;
  if (image) {
    element.style.backgroundImage = `url(${defaultImage})`;
  }
  element.classList = image
    ? "friends__friend-avatar"
    : "friends__friend-avatar friends__friend-avatar--empty";

  return element;
}

function createUrl(facebookUrl, userName) {
  const element = facebookUrl
    ? document.createElement("a")
    : document.createElement("p");

  element.classList = facebookUrl
    ? "friends__friend-user-full-name friends__friend-user-full-name--linked"
    : "friends__friend-user-full-name";
  element.innerText = userName;
  if (facebookUrl) {
    element.href = facebookUrl;
  }
  element.target = "_blank";

  return element;
}

function showMoreFriends(
  entryUrl,
  heartUrl,
  addFriendButtonElement,
  friendsListElement,
) {
  let nextApiPoint = entryUrl;

  this.loadMoreFriends = function () {
    if (nextApiPoint) {
      fetch(nextApiPoint)
        .then((res) => res.json())
        .then((data) => {
          data.results.forEach((r) => {
            const el = document.createElement("li");
            el.classList = "friends__friend js-friends__friend";
            el.appendChild(createImage(r.photo, heartUrl));
            el.appendChild(
              createUrl(r.facebook_url, r.display_name_or_full_name),
            );

            friendsListElement.appendChild(el);
          });
          nextApiPoint = data.next;
          if (!nextApiPoint) {
            addFriendButtonElement.remove();
          }
        });
    }
  };
}

if (document.querySelector(".js-friends")) {
  const friendList = document.querySelector(".js-friends"),
    apiEntryPoint = friendList.dataset.api,
    heartUrl = friendList.dataset.heartUrl,
    addFriendButtonElement = document.querySelector(
      ".js-friends__show-more-button",
    ),
    friendsListElement = document.querySelector(".js-friends__list"),
    showFriendsButtonFunction = new showMoreFriends(
      apiEntryPoint,
      heartUrl,
      addFriendButtonElement,
      friendsListElement,
    );

  showFriendsButtonFunction.loadMoreFriends();

  addFriendButtonElement.addEventListener(
    "click",
    showFriendsButtonFunction.loadMoreFriends,
  );
}
