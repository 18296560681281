export const hamburgerMenu = function () {
  const hamburger = document.querySelector("#topbar__hamburger");
  const nav = document.querySelector("#topbar__support-button");

  if (hamburger && nav) {
    const handleClick = () => {
      hamburger.classList.toggle("is-topbar__hamburger--active");
      nav.classList.toggle("topbar__support-button--active");
    };
    hamburger.addEventListener("click", handleClick);
  }
};
