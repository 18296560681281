const monthlyInput = document.querySelector(".js-payment__monthly-input"),
  oneTimeInput = document.querySelector(".js-payment__one-time-input"),
  monthlyDetails = document.querySelector(".js-payment__passport"),
  passportCheckbox = document.querySelector(".js-payment__passport-checkbox"),
  generalPaymentForm = document.querySelector(".js-general-payment-form");

function hideMonthlyInputs() {
  if (monthlyInput.checked === true) {
    monthlyDetails.classList.remove("is-payment--hidden");
    passportCheckbox.classList.remove("is-payment--hidden");
  } else {
    monthlyDetails.classList.add("is-payment--hidden");
    passportCheckbox.classList.add("is-payment--hidden");
  }
}

if (monthlyInput) {
  hideMonthlyInputs();
  monthlyInput.addEventListener("change", hideMonthlyInputs);
  oneTimeInput.addEventListener("change", hideMonthlyInputs);
} else if (generalPaymentForm && !monthlyInput && monthlyDetails) {
  monthlyDetails.classList.add("is-payment--hidden");
}
