const newsletterForm = document.querySelector("#newsletterForm");

if (newsletterForm) {
  newsletterForm.addEventListener("submit", function (e) {
    e.preventDefault();

    const submitResult = document.querySelector("#submitResult"),
      submitActionTarget = document.querySelector("#newsletterForm").action,
      agreementCheckbox = document.querySelector(".js-newsletter__checkbox"),
      newsletterAgreement = document.querySelector(".js-newsletter__agreement"),
      submitSpinner = document.querySelector(".js-newsletter__spinner-wrapper");

    const subscriptionProblem = function () {
      submitResult.classList.add("is-newsletter__feedback--error");
      submitSpinner.style.display = "none";
      submitResult.innerText = "Przepraszamy, wystąpił problem z subskrypcją.";
    };

    if (!this.email.value) {
      this.email.focus();
    } else if (!agreementCheckbox.checked) {
      newsletterAgreement.classList.add("textblink");
      setTimeout(() => {
        newsletterAgreement.classList.remove("textblink");
      }, 400);
    } else {
      submitSpinner.style.display = "inline-block";
      const formData = new FormData(this);
      fetch(submitActionTarget, {
        method: "POST",
        body: formData,
      })
        .then(function (response) {
          return response.json();
        })
        .then((json) => {
          if (json.success) {
            submitResult.classList.remove("is-newsletter__feedback--error");
            submitResult.classList.add("is-newsletter__feedback--success");
            submitSpinner.style.display = "none";
            submitResult.innerText = "Twoja subskrypcja jest aktywna!";
            newsletterForm.reset();
            setTimeout(() => {
              submitResult.innerText = "";
            }, 10000);
          } else {
            subscriptionProblem();
          }
        })
        .catch((e) => {
          subscriptionProblem();
          console.log("error: " + e);
        });
    }
  });
}
