import tippy from "tippy.js";

export default function handleTopbarDropdowns() {
  const dropdowns = document.querySelectorAll(".js-topbar-dropdown");
  dropdowns.forEach((dropdown) => {
    const content = dropdown.querySelector(
      ".js-topbar-dropdown-content",
    ).innerHTML;

    tippy(dropdown, {
      allowHTML: true,
      content,
      interactive: true,
      trigger: "click",
      arrow: true,
      offset: [0, 12],
      placement: "bottom",
      zIndex: 999,
      onShow: (instance) => {
        // handle martyna world popup openers
        const openers = instance.popper.querySelectorAll(
          ".js-open-martyna-world-redirect-popup",
        );
        openers.forEach((el) => {
          el.addEventListener("click", () => {
            const popup = document.querySelector(
              ".js-popup-martyna-world-redirect",
            );
            popup.classList.add("is-open");
          });
        });
      },
    });
  });
}
