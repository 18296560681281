import "tippy.js/dist/tippy.css";
import "../scss/main.scss";
import "./ajaxForm.js";
import "./slider";
import { showCookieDialog, bindCloseListener } from "./cookieInfo";
import { payboxModuleInit } from "./payboxModule";
import "./hideMonthly.js";
import "./fileUploadButton.js";
import "./printGratification.js";
import "./showFriends.js";
import "./newsletter.js";
import { hamburgerMenu } from "./hamburgerMenu";
import productSlider from "./productSlider";
import basket from "./basket";
import productsMainPageFilters from "./productsMainPageFilters";
import handleTopbarDropdowns from "./handleTopbarDropdowns";
import handleMartynaWorldRedirectPopup from "./handleMartynaWorldRedirectPopup";

showCookieDialog();
bindCloseListener();
payboxModuleInit();
hamburgerMenu();
productSlider();
basket();
productsMainPageFilters();
handleTopbarDropdowns();
handleMartynaWorldRedirectPopup();
