import Cookies from "js-cookie";

const cookieAlertName = "unaweza_cookie_closed";

function closeCookieAlert() {
  Cookies.set(cookieAlertName, "true", { path: "/", expires: 365 });
  const cookies = document.querySelector(".js-cookies");
  if (cookies) cookies.style.display = "none";
}

export function showCookieDialog() {
  if (Cookies.get(cookieAlertName) === undefined) {
    const cookies = document.querySelector(".js-cookies");
    if (cookies) cookies.style.display = "block";
  }
}

export function bindCloseListener() {
  const button = document.getElementById("close-cookie-button");
  if (button) button.addEventListener("click", closeCookieAlert);
}
