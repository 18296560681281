import Siema from "siema";

function Timer(fn, t) {
  let timerObj = setInterval(fn, t);

  this.stop = function () {
    if (timerObj) {
      clearInterval(timerObj);
      timerObj = null;
    }
    return this;
  };

  this.start = function () {
    if (!timerObj) {
      this.stop();
      timerObj = setInterval(fn, t);
    }
    return this;
  };
}

if (document.querySelector(".siema-1-slide")) {
  const mySiema = new Siema({
    loop: true,
    duration: 800,
    easing: "cubic-bezier(0.33, 1, 0.68, 1)",
    selector: ".siema-1-slide",
  });

  const prev = document.querySelectorAll(".js-carousel__prev");
  const next = document.querySelectorAll(".js-carousel__next");

  const siemaInterval = new Timer(() => mySiema.next(), 4000);
  const carousel = document.querySelector(".js-carousel__siema");

  siemaInterval;

  carousel.addEventListener("click", () => {
    siemaInterval.stop();
    setTimeout(function () {
      siemaInterval.start();
    }, 7000);
  });

  prev.forEach(function (e) {
    e.addEventListener("click", () => {
      siemaInterval.stop();
      setTimeout(function () {
        siemaInterval.start();
      }, 7000);
      mySiema.prev();
    });
  });
  next.forEach(function (e) {
    e.addEventListener("click", () => {
      siemaInterval.stop();
      setTimeout(function () {
        siemaInterval.start();
      }, 7000);
      mySiema.next();
    });
  });
}

[...document.querySelectorAll(".js-slider")].forEach((slider) => {
  const slideCount = slider.querySelector(".siema-3-slides").childElementCount;
  const mySiema3Slides = new Siema({
    loop: true,
    perPage: {
      768: Math.min(1, slideCount),
      769: Math.min(2, slideCount),
      1000: Math.min(3, slideCount),
    },
    selector: slider.querySelector(".siema-3-slides"),
  });

  const prev3 = slider.querySelector(".js-slider-3-slides__prev");
  const next3 = slider.querySelector(".js-slider-3-slides__next");

  prev3.addEventListener("click", () => mySiema3Slides.prev());
  next3.addEventListener("click", () => mySiema3Slides.next());
});
