import currency from "currency.js";
import Cookies from "js-cookie";

import initializeCounter from "./productsCounter";

export default function basket() {
  const getLocalStorageProducts = () => {
    const productsString = localStorage.getItem("basketProducts");
    return productsString ? JSON.parse(productsString) : [];
  };

  const updateTopbarBasketProductsCounter = () => {
    const counters = document.querySelectorAll(".js-topbar-basket-counter");
    const products = getLocalStorageProducts();

    if (!products.length) {
      counters.forEach((counterEl) => {
        counterEl.style.opacity = "0";
      });
      return;
    }

    counters.forEach((counterEl) => {
      counterEl.style.opacity = "1";
      const productsQuantity = products.reduce((curr, next) => {
        return curr + next.quantity;
      }, 0);

      // show hearth icon if user adds more than 99 items to basket
      if (productsQuantity > 99) {
        const hearthIcon = document.createElement("i");
        hearthIcon.classList.add("fas", "fa-heart");
        counterEl.innerText = "";
        counterEl.appendChild(hearthIcon);
        return;
      }
      counterEl.innerText = productsQuantity;
    });
  };
  updateTopbarBasketProductsCounter();

  // removes products of given id from local storage
  const removeProductsFromBasket = (id) => {
    const products = getLocalStorageProducts();
    const productIndex = products.findIndex(
      (el) => parseInt(el.id, 10) === parseInt(id, 10),
    );

    if (productIndex === -1) {
      return;
    }
    const newProducts = products.slice();
    newProducts.splice(productIndex, 1);
    localStorage.setItem("basketProducts", JSON.stringify(newProducts));
  };

  const setProductQuantity = ({ id, quantity }) => {
    const products = getLocalStorageProducts();
    const productIndex = products.findIndex((el) => parseInt(el.id, 10) === id);

    if (productIndex === -1) {
      return;
    }
    const newProducts = products.slice();
    newProducts.splice(productIndex, 1, {
      id,
      quantity,
    });
    localStorage.setItem("basketProducts", JSON.stringify(newProducts));
  };

  const handleProductPageProductsCounter = () => {
    const productPageAddToBasketBtn = document.querySelector(
      ".product-page .js-add-to-basket-btn",
    );
    if (!productPageAddToBasketBtn) {
      return;
    }
    // update quantity data prop on add to basket button when counter changes
    const productPageProductCounter = initializeCounter({
      counterEl: document.querySelector(".js-product-page-counter"),
      targetEl: productPageAddToBasketBtn,
      targetElDataProp: "quantity",
    });
    // reset counter after product adding
    productPageAddToBasketBtn.addEventListener("click", () => {
      productPageProductCounter.reset();
    });
  };
  handleProductPageProductsCounter();

  const addedToBasketPopup = document.querySelector(
    ".js-added-to-basket-popup",
  );
  const handleAddedToBasketPopupCloseButtons = () => {
    if (!addedToBasketPopup) {
      return;
    }
    const closePopupButtons =
      addedToBasketPopup.querySelectorAll(".js-popup-close");

    closePopupButtons.forEach((closeEl) => {
      closeEl.addEventListener("click", function () {
        addedToBasketPopup.classList.remove("is-open");
      });
    });
  };
  handleAddedToBasketPopupCloseButtons();

  const addProductToBasket = ({ id, quantity }) => {
    const oldProducts = getLocalStorageProducts();
    const addedProductIndex = oldProducts.findIndex((el) => el.id === id);

    // check if product was already added
    if (addedProductIndex > -1) {
      const oldQuantity = parseInt(oldProducts[addedProductIndex].quantity, 10);
      const newProducts = oldProducts.slice();
      newProducts.splice(addedProductIndex, 1, {
        id,
        quantity: oldQuantity + quantity,
      });
      localStorage.setItem("basketProducts", JSON.stringify(newProducts));
    } else {
      localStorage.setItem(
        "basketProducts",
        JSON.stringify([...oldProducts, { id, quantity }]),
      );
    }
  };

  // adding to basket and showing added to basket popup
  const handleAddToBasketButtons = () => {
    if (!addedToBasketPopup) {
      return;
    }

    const addToBasketButtons = document.querySelectorAll(
      ".js-add-to-basket-btn",
    );
    addToBasketButtons.forEach((btnEl) => {
      btnEl.addEventListener("click", function () {
        const { pageId, quantity, imageSrc, productName, productPrice } =
          this.dataset;

        const imageEl = addedToBasketPopup.querySelector(".js-popup-image");
        if (imageSrc && imageEl) {
          imageEl.src = imageSrc;
        }
        const nameEl = addedToBasketPopup.querySelector(".js-product-name");
        if (productName && nameEl) {
          nameEl.innerText = productName;
        }
        const priceEl = addedToBasketPopup.querySelector(".js-product-price");
        if (productPrice && priceEl) {
          priceEl.innerText = `${productPrice} zł`;
        }

        const quantityEl = addedToBasketPopup.querySelector(
          ".js-product-quantity",
        );
        if (quantity && quantityEl) {
          quantityEl.style.display = "block";
          quantityEl.innerText = `x ${quantity}`;
        } else {
          quantityEl.style.display = "none";
        }

        addedToBasketPopup.classList.add("is-open");

        // handle no quantity data prop and int conversion
        const finalQuantity = parseInt(quantity || 1, 10);

        addProductToBasket({ id: pageId, quantity: finalQuantity });
        updateTopbarBasketProductsCounter();
      });
    });
  };
  handleAddToBasketButtons();

  const handleProductsLists = (targetEl, products) => {
    // update total price element
    const updateTotalPrice = () => {
      const totalSumEl = document.querySelector(
        ".js-basket-page .js-payment-sum",
      );
      const totalSum = getLocalStorageProducts()
        .map(({ id, quantity }) => {
          const productDetails = products.find(
            (el) => el.id === parseInt(id, 10),
          );
          return {
            price: productDetails.price,
            id,
            quantity,
          };
        })
        .reduce((curr, next) => {
          return currency(next.price).multiply(next.quantity).add(curr);
        }, 0);
      totalSumEl.innerText =
        currency(totalSum).format({
          precision: 2,
          symbol: "",
        }) + " zł";
    };
    updateTotalPrice();

    // create products and append to DOM
    const template = targetEl.querySelector(".js-product-template");
    products.forEach(
      ({
        main_photo: { url: imgSrc },
        price,
        title,
        quantity,
        id,
        product_page_url,
      }) => {
        // set markup
        const templateClone = template.cloneNode(true);
        templateClone.classList.remove("js-product-template");
        templateClone.classList.add("js-basket-product");
        templateClone.querySelector(".js-product-image").src = imgSrc;

        // set product title
        const productLink = document.createElement("a");
        productLink.href = product_page_url;
        productLink.innerText = title;
        templateClone
          .querySelector(".js-product-name")
          .appendChild(productLink);

        templateClone.querySelector(
          ".js-product-price",
        ).innerText = `${price} zł`;

        if (quantity > 1) {
          templateClone
            .querySelector(".js-basket-product-counter .remove")
            .classList.remove("is-disabled");
        }
        templateClone.querySelector(
          ".js-basket-product-counter .value",
        ).innerText = quantity;
        templateClone.querySelector(".js-product-price-sum").innerText =
          currency(price)
            .multiply(quantity)
            .format({ precision: 2, symbol: "" }) + " zł";

        // handle products counter
        const counterEl = templateClone.querySelector(
          ".js-basket-product-counter",
        );
        initializeCounter({
          counterEl,
          onChange: (value) => {
            setProductQuantity({ id, quantity: value });
            updateTopbarBasketProductsCounter();
            templateClone.querySelector(".js-product-price-sum").innerText =
              currency(price)
                .multiply(value)
                .format({ precision: 2, symbol: "" }) + " zł";
            updateTotalPrice();
          },
        });
        // handle product delete button
        const deleteItemBtn = templateClone.querySelector(".js-product-remove");
        deleteItemBtn.addEventListener("click", () => {
          removeProductsFromBasket(id);
          templateClone.remove();
          updateTopbarBasketProductsCounter();
          updateTotalPrice();
          if (!getLocalStorageProducts().length) {
            document.querySelector(".js-basket-page").classList.add("is-empty");
          }
        });

        targetEl.appendChild(templateClone);
      },
    );
  };

  const handleBasketProducts = () => {
    const localStorageProducts = getLocalStorageProducts();
    const productsIds = localStorageProducts.map((obj) => obj.id);
    const basketPage = document.querySelector(".js-basket-page");
    if (!basketPage) {
      return;
    }

    if (!productsIds.length) {
      basketPage.classList.remove("is-loading");
      basketPage.classList.add("is-empty");
      return;
    }

    fetch(`/api/v2/products/?ids=${productsIds.join(",")}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((res) => {
        // order products correctly
        return localStorageProducts.map((product) => {
          const { id } = product;
          const productDetails = res.find((obj) => obj.id === parseInt(id, 10));
          return {
            ...productDetails,
            quantity: product.quantity,
          };
        });
      })
      .then((products) => {
        // add products to lists
        const productsLists = document.querySelectorAll(".js-products-list");
        productsLists.forEach((listEl) => {
          handleProductsLists(listEl, products);
        });
      })
      .catch((error) => {
        basketPage.classList.add("has-error");
        throw error;
      })
      .finally(() => {
        if (basketPage) {
          basketPage.classList.remove("is-loading");
        }
      });
  };
  handleBasketProducts();

  const handleBasketPayment = () => {
    const basketPage = document.querySelector(".js-basket-page");
    if (!basketPage) {
      return;
    }
    const paymentBtn = basketPage.querySelector(".js-basket-payment-button");
    if (!paymentBtn) {
      return;
    }

    paymentBtn.addEventListener("click", function () {
      this.classList.add("is-loading");

      fetch("/api/v2/create_order/", {
        method: "POST",
        body: JSON.stringify({
          ordered_products: getLocalStorageProducts().map(
            ({ id, quantity }) => {
              return {
                related_product_page_id: id,
                related_product_quantity: quantity,
              };
            },
          ),
        }),
        headers: {
          "X-CSRFToken": Cookies.get("csrftoken"),
          "Content-Type": "application/json",
          "Accept-Language": document.querySelector("html").lang,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          window.location.href = `${window.location.origin}${res.redirect_url}`;
        })
        .catch((error) => {
          basketPage.classList.add("has-error");
          throw error;
        })
        .finally(() => {
          this.classList.remove("is-loading");
        });
    });
  };
  handleBasketPayment();
}
