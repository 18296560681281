import Splide from "@splidejs/splide";

export default function productSlider() {
  const mainSliderEl = document.querySelector(".product-page .slider-main");
  if (!mainSliderEl) {
    return;
  }

  const mainSlider = new Splide(mainSliderEl, {
    type: "loop",
    arrows: true,
    pagination: false,
  });

  const thumbnailsSliderEl = document.querySelector(
    ".product-page .slider-thumbnails",
  );
  let thumbnailsSlider;
  if (thumbnailsSliderEl) {
    thumbnailsSlider = new Splide(thumbnailsSliderEl, {
      type: "loop",
      fixedWidth: 160,
      fixedHeight: 130,
      gap: 29,
      pagination: false,
      cover: true,
      arrows: false,
      isNavigation: true,
    });

    mainSlider.sync(thumbnailsSlider);
  }

  mainSlider.mount();

  if (thumbnailsSlider) {
    thumbnailsSlider.mount();
  }
}
