export default function mainPageProductsFilters() {
  const form = document.querySelector(".js-main-page-filters-form");
  if (!form) {
    return;
  }
  const selects = form.querySelectorAll(".js-main-page-filter-select");
  selects.forEach((select) => {
    select.addEventListener("change", () => {
      form.submit();
    });
  });

  const queryParams = new URL(document.location).searchParams;
  if (
    queryParams.get("story") ||
    queryParams.get("category") ||
    queryParams.get("q")
  ) {
    const offset = 40;
    const formPosition = form.getBoundingClientRect().top;
    window.scrollTo({
      top: formPosition - offset,
      behavior: "smooth",
    });
  }
}
