const setTransform = (e, t) => {
  e.style.MozTransform = t;
  e.style.transform = t;
};

const payboxFromTop = () => {
  if (window.scrollY < 185) {
    return 200 - window.scrollY;
  }
  return 15;
};

const scrollPosition = (paybox) => {
  const payboxContainer = document.querySelector(".js-paybox-container");
  const payboxToThisEl = document.querySelector(".js-paybox-to-this");
  const transformValue =
    payboxContainer.getBoundingClientRect().top * -1 + payboxFromTop() + "px";

  if (
    payboxToThisEl.getBoundingClientRect().top >
    paybox.offsetHeight + payboxFromTop()
  ) {
    setTransform(paybox, `translate(0, ${transformValue})`);
  }
};

export const payboxModuleInit = () => {
  const paybox = document.querySelector(".js-paybox");
  if (paybox) {
    scrollPosition(paybox);
    window.addEventListener("scroll", () => {
      scrollPosition(paybox);
    });
    window.addEventListener("resize", () => {
      scrollPosition(paybox);
    });
  }
};
