const setError = (
  form,
  fieldName,
  showError = true,
  errorSelector = ".js-error-message",
) => {
  const field = form.querySelector('input[name="' + fieldName + '"]');
  if (!field) {
    return;
  }
  const errorBox = field.closest(".payment__error-box");
  if (!errorBox) {
    return;
  }
  const errorEl = errorBox.querySelector(`${errorSelector}`);
  if (showError) {
    errorBox.classList.add("payment__error-box--display");
    errorEl.classList.add("is-visible");
  } else {
    errorBox.classList.remove("payment__error-box--display");
    errorEl.classList.remove("is-visible");
  }
};

function validateEmail(email) {
  // https://stackoverflow.com/a/46181
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/;
  return re.test(String(email).toLowerCase());
}

function validateImageSize(imageSize, fileMaxSize) {
  return imageSize <= fileMaxSize;
}

const validate = (form) => {
  const formData = new FormData(form);
  let ok = true;

  const resetErrors = () => {
    ["value", "recurring", "email", "payment_method", "terms_accepted"].forEach(
      (fieldName) => {
        setError(form, fieldName, false);
      },
    );
    setError(form, "value", false, ".js-error-message-wrong-value");
    setError(form, "email", false, ".js-error-message-wrong-email");
    setError(form, "image", false);
  };

  resetErrors();

  // required fields
  [
    "value",
    "recurring",
    "email",
    "payment_method",
    "terms_accepted",
    "shop_consent",
  ].forEach((fieldName) => {
    const field = form.querySelector(`input[name=${fieldName}]`);
    if (!field) {
      ok = true;
      return;
    }
    const fieldValue = formData.get(fieldName);
    if (fieldValue === null || fieldValue === "") {
      setError(form, fieldName);
      ok = false;
    }
  });

  if (!ok) return ok;

  // value
  const valueStr = formData.get("value");
  const value = Number(valueStr);
  if (
    isNaN(value) ||
    value <= 0 ||
    // do not allow decimal values in general payment form value field
    (form.classList.contains("js-general-payment-form") &&
      !Number.isInteger(value))
  ) {
    setError(form, "value", true, ".js-error-message-wrong-value");
    ok = false;
  }

  // email
  const email = formData.get("email");
  if (!validateEmail(email)) {
    setError(form, "email", true, ".js-error-message-wrong-email");
    ok = false;
  }

  // fileSize
  const image = formData.get("image");
  if (image) {
    const fileMaxSize = form.elements["image"].dataset.fileMaxSize;
    if (!validateImageSize(image.size, fileMaxSize)) {
      setError(form, "image");
      ok = false;
    }
  }
  return ok;
};

const registerAJAXForm = (form) => {
  form.addEventListener("submit", function (e) {
    // do not send the form default way
    e.preventDefault();

    const formValid = validate(form);
    if (!formValid) {
      form
        .querySelector(".payment__error-box--display")
        .scrollIntoView({ block: "center", behavior: "smooth" });
      return;
    }

    // reset basket after successful order payment form validation
    if (form.classList.contains("js-order-payment-form")) {
      localStorage.removeItem("basketProducts");
    }

    form.submit();
  });
};

const registerAJAXForms = (root) => {
  [...root.querySelectorAll(".ajax-form")].forEach(registerAJAXForm);
  if (root.classList && root.classList.contains("ajax-form"))
    registerAJAXForm(root);
};

registerAJAXForms(document);

[...document.querySelectorAll(".ajax-form")].forEach((form) => {
  const valueTextInput = form.querySelector('input[name="value"][type="text"]');
  if (!valueTextInput) {
    return;
  }
  const valueRadioInputs = [
    ...form.querySelectorAll('input[name="value"][type="radio"]'),
  ];
  const recurringInputs = [...form.querySelectorAll('input[name="recurring"]')];
  const paymentMethodRadioInputs = [
    ...form.querySelectorAll('input[name="payment_method"][type="radio"]'),
  ];

  // handle 'value' field with multiple inputs
  const useValueRadioInput = () => {
    // clear text input
    valueTextInput.value = "";
    // change the name so radio can POST their value
    valueTextInput.name = "";
  };
  const useValueTextInput = () => {
    // clear radio input
    valueRadioInputs.forEach((input) => {
      input.checked = false;
    });
    // restore name
    valueTextInput.name = "value";
  };
  valueRadioInputs.forEach((input) => {
    input.addEventListener("change", useValueRadioInput);
    if (input.checked) useValueRadioInput();
  });
  valueTextInput.addEventListener("change", useValueTextInput);

  // recurring payment is supported only by payu
  const updatePaymentMethods = () => {
    const recurring = new FormData(form).get("recurring");
    paymentMethodRadioInputs.forEach((input) => {
      const disabled = recurring === "True" && input.value !== "pay-u";
      input.disabled = disabled;
      if (disabled && input.checked) {
        input.checked = false;
      }
    });
  };
  recurringInputs.forEach((input) => {
    input.addEventListener("change", updatePaymentMethods);
  });
  updatePaymentMethods();
});
